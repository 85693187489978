<template>
  <div class="section">
    <div v-if="!whiteLabel" class="container">
      <div class="section-title-wide no-bottom-space">
        <h6 class="subheading text-primary-1">{{ content.preview_text }}!</h6>

        <h1>{{ content.heading }}</h1>
        <h4>{{ content.sub_header }}</h4>
         <video width="400" v-bind:src="content.explainer_video" controls/>
        <div id="servicebot-subscription-portal"></div>
        <!-- <img src="images/molly.gif" /> -->
      </div>
    </div>

    <!--White Label Branding-->
    <div v-if="whiteLabel" class="container">
      <div class="section-title-wide no-bottom-space">
        <h2 class="">Welcome to {{ name }}!</h2>

        <a class="button w-inline-block" href="/">Enter</a>
      </div>
    </div>
  </div>
</template>
 
<script>
import PageService from "@/services/pageService.js";
export default {
  data: () => ({
    loading: false,
    successUrl: process.env.VUE_APP_URL + "/membership/almost-there",
    cancelUrl: process.env.VUE_APP_URL + "/membership/checkout",
    email: null,
    content: [],
    serviceBotId: process.env.VUE_APP_SERVICEBOT_ID,
    name: process.env.VUE_APP_COMPANY_NAME,
    settings: {
      billing_page_id: "HYqb5JVvsx72xXc7WqoC"
    },
  }),
  computed: {
    whiteLabel: function () {
      if (process.env.VUE_APP_WHITELABEL == "false") return false;
      return true;
    },
  },
  methods: {
    getBillflow: function () {
      if (this.$route.query.email) {
        this.settings.email = this.$route.query.email;
        this.settings.hash = this.$route.query.hmac;
      } 
      else 
      {
        this.settings.email = this.$auth.user.email;
        if(this.settings.email==null)
        {
          this.init(this.getBillflow);
          return;
        }
        console.log("No params Detected");
        console.log(this.$auth.user.email);//
        this.hmac = this.$auth.user["https://theagentnest.com/hmac"];
  
      }

      var s = document.createElement("script");
      s.src =
        "https://morealty.blob.core.windows.net/public/servicebot.js";
      s.async = true;
      s.type = "text/javascript";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    },
    init(fn) {
    // have to do this nonsense to make sure auth0Client is ready
    var instance = this.$auth;
    instance.$watch("loading", loading => {
      if (loading === false) {
        fn();
        console.log("Auth is ready");
      }
    });
    },

  },
  
  mounted() {
    window.billflowSettings = this.settings;
    this.getBillflow();
  },
  created() {
    
    PageService.getPage("plan-picker-page")
      .then((response) => {
        this.content = response.data.data.fields;
      })
      .catch((error) => {
        console.log("there was an error getting the content", error);
      });
  }
};
</script> 
<style>
.free-trial {
  width: 50%;
  margin-top: 40px;
}
.servicebot-pricing-table.__column-count-more-than-3 .__tier {
  width: calc(100% / 4 + 20px) !important;
}
/* .servicebot-currency:before {
  content: "$0 due today! ";
}
.__description:after {
  content: " after ";
} */
/* .__price {
  color: white;
}
.__signup {
  color: white !important;
}
.__name {
  color: black !important;

} */
</style>